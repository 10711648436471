/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderedProjectDurationSummary
 */
export interface OrderedProjectDurationSummary {
    /**
     * 
     * @type {string}
     * @memberof OrderedProjectDurationSummary
     */
    projectName: string;
    /**
     * 
     * @type {number}
     * @memberof OrderedProjectDurationSummary
     */
    doneAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderedProjectDurationSummary
     */
    calculatedDuration: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderedProjectDurationSummary
     */
    isOption: boolean;
}

/**
 * Check if a given object implements the OrderedProjectDurationSummary interface.
 */
export function instanceOfOrderedProjectDurationSummary(value: object): value is OrderedProjectDurationSummary {
    if (!('projectName' in value) || value['projectName'] === undefined) return false;
    if (!('doneAmount' in value) || value['doneAmount'] === undefined) return false;
    if (!('calculatedDuration' in value) || value['calculatedDuration'] === undefined) return false;
    if (!('isOption' in value) || value['isOption'] === undefined) return false;
    return true;
}

export function OrderedProjectDurationSummaryFromJSON(json: any): OrderedProjectDurationSummary {
    return OrderedProjectDurationSummaryFromJSONTyped(json, false);
}

export function OrderedProjectDurationSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderedProjectDurationSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'projectName': json['projectName'],
        'doneAmount': json['doneAmount'],
        'calculatedDuration': json['calculatedDuration'],
        'isOption': json['isOption'],
    };
}

  export function OrderedProjectDurationSummaryToJSON(json: any): OrderedProjectDurationSummary {
      return OrderedProjectDurationSummaryToJSONTyped(json, false);
  }

  export function OrderedProjectDurationSummaryToJSONTyped(value?: OrderedProjectDurationSummary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'projectName': value['projectName'],
        'doneAmount': value['doneAmount'],
        'calculatedDuration': value['calculatedDuration'],
        'isOption': value['isOption'],
    };
}

