import {
  IonContent,
  IonFooter,
  IonLoading,
  IonPage,
  IonText,
  useIonToast,
} from '@ionic/react';
import React, { useMemo, useState } from 'react';

import { RouteComponentProps, useHistory } from 'react-router';
import {
  Button,
  NumberInput,
  PictureViewer,
  TakePhotoButton,
  Textarea,
} from '~/components/defaultUIComponents';
import Toolbar from '../components/Toolbar/Toolbar';
import routes from '../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { PictureType } from '~/types';
import { runningAnySchema } from '~/utils/yup/schemata';
import {
  useEndSpecialJob,
  useRunningSpecialJob,
  useSpecialJob,
} from '~/api/SpecialJobApi';
import { useEmployeeId } from '~/state/auth';
import _ from 'lodash';

type RunSpecialJobPageProps = RouteComponentProps<{ specialJob: string }>

export default function RunSpecialJob(props: RunSpecialJobPageProps) {
  const t = useTranslate();
  const history = useHistory();
  const employeeId = useEmployeeId();

  const specialJobId = Number(props.match.params.specialJob);
  const { data: specialJob, isLoading: specialJobLoading } = useSpecialJob({
    specialJobId,
    employeeId,
  });
  const { data: runningSpecialJob, isLoading: runningSpecialJobLoading } =
    useRunningSpecialJob({
      employeeId,
      specialJobId,
    });

  const { mutate: endSpecialJob } = useEndSpecialJob({
    onSuccess: () => {
      history.replace(routes.WEEK_PLAN);
    },
  });

  const [event] = useState<React.FormEvent>();

  const runningPictures = useMemo(
    () =>
      _.concat(
        runningSpecialJob?.pictures ?? [],
        runningSpecialJob?.special_job?.special_job_pictures ?? [],
      ),
    [runningSpecialJob],
  );

  const [description] = useState(runningSpecialJob?.special_job.description);
  const [comment, setComment] = useState(description);
  const [amount, setAmount] = useState<number>(0);
  const [present] = useIonToast();

  const showToastSchemaIsNotValid = (message: string = 'yup.notValid') => {
    void present({
      message: t(message),
      duration: 1000,
      color: 'danger',
    });
  };

  const onSubmit = (e?: React.FormEvent) => {
    const localEvent = e || event;
    localEvent?.preventDefault();

    runningAnySchema
      .isValid({
        amount,
        comment,
      })
      .then(function(valid) {
        if (valid && comment) {
          endSpecialJob({
            runningSpecialJobId: Number(runningSpecialJob?.id),
            amount,
            comment,
          });
        } else showToastSchemaIsNotValid();
      })
      .catch((error) => {
        showToastSchemaIsNotValid(error as string);
      });
  };

  const handleAmountChange = (value: number) => {
    setAmount(value);
  };

  const handleCommentChange = (value: string | null | undefined) => {
    if (!value) {
      setComment('');
      return;
    }
    setComment(value.trimStart());
  };

  return (
    <form onSubmit={onSubmit}>
      <IonPage>
        <Toolbar>{t('runSpecialJob.toolbar.title')}</Toolbar>
        <IonContent>
          <IonLoading isOpen={specialJobLoading || runningSpecialJobLoading} />
          <div className="flex flex-col gap-3 px-3 pt-5">
            {specialJob?.amount && (
              <IonText className="text-gray-800">{`${t(
                'runSpecialJob.orderedAmount',
              )} ${specialJob.amount}`}</IonText>
            )}
            <NumberInput
              value={amount || undefined}
              onChange={handleAmountChange}
              name="amountInput"
              label="runSpecialJob.amount"
              placeholder=""
              min="0"
              max="99"
              required
            />

            <Textarea
              value={comment}
              onChange={(val) => handleCommentChange(val)}
              name="messageTextarea"
              label="runSpecialJob.description"
              autocorrect="on"
              clearInput
              spellcheck
              rows={3}
            />
            <PictureViewer
              pictureIds={runningPictures}
              pictureType={PictureType.SPECIALJOB}
              projectOrRunningAnyId={runningSpecialJob?.id}
            />
          </div>
        </IonContent>
        <IonFooter className="bg-white w-full flex">
          <Button type="submit" className="w-full">
            runSpecialJob.end
          </Button>
          <TakePhotoButton
            runningAnyId={runningSpecialJob?.id ?? ''}
            pictureType={PictureType.SPECIALJOB}
            className="h-14 w-15"
          />
        </IonFooter>
      </IonPage>
    </form>
  );
}

