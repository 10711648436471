import { QueryKey, useMutation, UseMutationOptions, useQueryClient, UseQueryOptions, } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  Configuration,
  OrderApi,
  OrderedJobsApi,
  OrderedProjectApi,
  SummedOrderedJobsApi,
  SummedOrderedProjectsApi
} from "@its4plan/ordering"
import { createContext, ReactNode, useContext } from "react";
import { useAuthStore } from "~/state/auth";

function baseUrlFactory(prefix: string, fallback: string) {
  const hostname = window.location.hostname
  if (hostname === "localhost") {
    return fallback
  }
  const [_, ...rest] = hostname.split(".")
  return "https://" + prefix + "." + rest.join(".")
}

axios.defaults.baseURL = baseUrlFactory("api", import.meta.env.VITE_APP_PROD_URL as string) + "/api"

export type QueryOptions<D, T = D> = Omit<
  UseQueryOptions<D, AxiosError, T>,
  'queryKey' | 'queryFn'
>;

export type MutationOptions<D = unknown, V = void> = Omit<
  UseMutationOptions<D, AxiosError, V>,
  'mutationFn' | 'mutationKey'
>;

type UseApiMutationOptions<D, V> = UseMutationOptions<D, AxiosError, V> & {
  invalidateKeys?: QueryKey;
};

export type ApiError = {
  detail: string;
};

export const useApiMutation = <D = unknown, V = void>({
  invalidateKeys,
  onSuccess,
  ...options
}: UseApiMutationOptions<D, V>) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    onSuccess: async (data, variables, context) => {
      if (onSuccess) onSuccess(data, variables, context);
      if (invalidateKeys)
        await queryClient.invalidateQueries({ queryKey: invalidateKeys });
    },
  });
};

export function unwrap<Data>(res: AxiosResponse<Data>): Data {
  return res.data;
}

function registerApis(authToken?: string) {
  const config = new Configuration({
    basePath: baseUrlFactory("orders", import.meta.env.VITE_APP_ORDERING_URL as string),
    headers: { Authorization: authToken ? `Bearer ${authToken}` : '' },
  });
  return {
    orderApi: new OrderApi(config),
    orderedProjectApi: new OrderedProjectApi(config),
    orderedJobApi: new OrderedJobsApi(config),
    summedOrderedProjectAPi: new SummedOrderedProjectsApi(config),
    summedOrderedJobsApi: new SummedOrderedJobsApi(config),
  };
}

type TApiContext = ReturnType<typeof registerApis>;

const ApiContext = createContext<TApiContext>(null as unknown as TApiContext);

export function useApiContext() {
  const apiContext = useContext(ApiContext);
  if (apiContext === null) {
    throw new Error('No Api Context found!');
  }
  return apiContext;
}

export function ApiContextProvider(props: { children: ReactNode }) {
  const apis = useAuthStore(store => registerApis(store.token))
  return (
    <ApiContext.Provider value={apis}>{props.children}</ApiContext.Provider>
  );
}
