import '@total-typescript/ts-reset';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactHashRouter as IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import Menu from './components/Menu';
import routes from './constants/routes.json';
import { appPages } from './menuRoutes';
import Authenticate from './pages/Authenticate';
import QRCodePage from './pages/CodeScannerPage';
import CreateNewJob from './pages/CreateNewJob';
import Dayplan from './pages/Dayplan';
import Overseer from './pages/Overseer';
import Orders from './pages/Orders';
import ProjectPage from './pages/Project';
import Routines from './pages/Routines';
import RunJob from './pages/RunJob';
import RunNewJob from './pages/RunNewJob';
import RunningJobs from './pages/RunningJobs';
import RunRoutine from './pages/RunRoutine';
import RunSpecialJob from './pages/RunSpecialJob';
import Settings from './pages/Settings';
import SpecialJob from './pages/SpecialJob';
import TimeTracking from './pages/TimeTracking';
import Weekplan from './pages/Weekplan';
import { createHashHistory as createHistory } from 'history';

import { FeatureFlag, isFeatureFlagActive } from './utils/featureFlag';
import { useSettingsStore } from './state/settings';
import { useAuthStore } from './state/auth';
import { RoleCode } from '~/types';

const findFirstActiveMenuEntry = (
  featureFlags: string,
  hasOverseerRole: boolean | undefined,
) => {
  const activeMenuEntry = _.find(appPages(hasOverseerRole), (appPage) =>
    featureFlags.includes(appPage.featureFlag),
  );
  if (activeMenuEntry) {
    return activeMenuEntry.url;
  }
  return routes.AUTHENTICATE;
};

const RenderRedirect: React.FC<{ path: string }> = ({ path }) => {
  return <Redirect to={path} />;
};

type PrivateRouteProps = RouteProps & {
  featureFlag: FeatureFlag;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  featureFlag,
  ...props
}) => {
  const { feature_flags: featureFlags = '', authenticated: isLoggedIn } =
    useAuthStore();
  if (isLoggedIn && isFeatureFlagActive(featureFlags, featureFlag)) {
    return (
      <Route path={path} {...props} />
    );
  }
  return <Redirect to={routes.AUTHENTICATE} />;
};

const history = createHistory();

const App: React.FC = () => {
  const { fetchLanguage } = useSettingsStore();
  const {
    authenticated: isLoggedIn = false,
    feature_flags: featureFlags = '',
  } = useAuthStore();
  const hasOverseerRole = useAuthStore(
    (store) => !!store.roles?.find((r) => r.role.code === RoleCode.OVERSEER),
  );

  useEffect(() => {
    void fetchLanguage();
  }, []);

  // GMT-0
  moment.tz.setDefault('Africa/Monrovia');

  return (
    <IonApp>
      <IonReactRouter history={history}>
        <Menu />
        <IonRouterOutlet id="main" >
          <PrivateRoute
            featureFlag={FeatureFlag.TIMETRACKING}
            path={routes.TIME_TRACKING}
            exact
            component={TimeTracking}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.ORDERS}
            path={routes.ORDERS}
            exact
            component={Orders}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.ORDERS}
            path={routes.PROJECT}
            exact
            component={ProjectPage}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.RUNNINGJOBS}
            path={routes.RUN_JOB}
            exact
            component={RunJob}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.DAYPLAN}
            path={routes.DAY_PLAN}
            exact
            component={Dayplan}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.WEEKPLAN}
            path={routes.WEEK_PLAN}
            exact
            component={Weekplan}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.ROUTINES}
            path={routes.ROUTINES}
            exact
            component={Routines}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.ROUTINES}
            path={routes.RUN_ROUTINE}
            exact
            component={RunRoutine}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.RUNNINGJOBS}
            path={routes.RUNNING_JOBS}
            exact
            component={RunningJobs}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.RUNNINGJOBS}
            path={routes.JOBS_COLLEAGUES}
            exact
            render={() => {
              if (hasOverseerRole) {
                return <Overseer />;
              }
              return <Redirect to={routes.ROOT} />;
            }}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.SPECIALJOBS}
            path={routes.SPECIAL_JOBS}
            exact
            component={SpecialJob}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.SPECIALJOBS}
            path={routes.RUN_SPECIAL_JOBS}
            exact
            component={RunSpecialJob}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.NEWJOBS}
            path={routes.CREATE_JOB}
            exact
            component={CreateNewJob}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.NEWJOBS}
            path={routes.RUN_NEW_JOB}
            exact
            component={RunNewJob}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.TIMETRACKING}
            path={routes.TIME_TRACKING}
            exact
            component={TimeTracking}
          />
          <PrivateRoute
            featureFlag={FeatureFlag.TIMETRACKING}
            path={routes.SETTINGS}
            exact
            component={Settings}
          />
          <Route path={routes.CODE_SCANNING} exact component={QRCodePage} />
          <Route path={routes.AUTHENTICATE} exact component={Authenticate} />
          <Route
            render={() =>
              !isLoggedIn ? (
                <Redirect to={routes.AUTHENTICATE} />
              ) : (
                <RenderRedirect
                  path={findFirstActiveMenuEntry(featureFlags, hasOverseerRole)}
                />
              )
            }
          />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
