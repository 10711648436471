import {
  IonContent,
  IonFooter,
  IonLoading,
  IonPage,
  useIonToast,
} from '@ionic/react';
import { useMemo, useState } from 'react';

import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  Button,
  CancelModal,
  ChangeRequestModal,
  FlexRow,
  Label,
  LabeledText,
  NumberInput,
  PictureViewer,
  TakePhotoButton,
  Text,
  Textarea,
  TookTooLongModal,
} from '~/components/defaultUIComponents';
import { LinkButton } from '~/components/defaultUIComponents/Button/LinkButton';
import Toolbar from '../components/Toolbar/Toolbar';
import routes from '../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { useEmployeeId } from '~/state/auth';
import { PictureType, TookTooLongType } from '~/types';
import { runningAnySchema } from '~/utils/yup/schemata';
import { useEndJob, useJob, useRunningJob } from '~/api/JobApi';
import { useDisclosure } from '~/utils/disclosure';
import { isAxiosError } from 'axios';
import { ApiError } from '~/api/common';
import { useSummedOrderedJob } from '~/api/OrderingApi';

type RunJobPageProps = RouteComponentProps<{ id: string }>

export default function RunJob(props: RunJobPageProps) {
  const t = useTranslate();
  const history = useHistory();
  const id = props.match.params.id
  const jobId = Number(id);
  const employeeId = useEmployeeId();

  const { data: runningJob, isLoading: runningJobLoading } = useRunningJob({
    employeeId,
    enabled: !!employeeId,
  });
  const { data: summedJob, isLoading: summedJobLoading } = useSummedOrderedJob({
    jobId,
    enabled: !!jobId,
  });
  const { data: job } = useJob({ jobId });

  const [present] = useIonToast();

  const { mutate: endJob, isPending } = useEndJob({
    onSuccess: () => {
      history.replace(routes.RUNNING_JOBS);
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        const res = error.response?.data as ApiError | undefined;
        if (res) {
          if (res.detail === 'error.running_job.took_too_long') {
            setTookTooLongType(TookTooLongType.jobTookTooLong);
            return rejectionModal.open();
          } else if (res.detail === 'error.running_job.took_reg_too_long') {
            setTookTooLongType(TookTooLongType.jobTookRegTooLong);
            return rejectionModal.open();
          }
        }
      }
      void present({
        message: t('error.unknown'),
        duration: 1000,
        color: 'danger',
      });
    },
  });

  const loading = runningJobLoading && summedJobLoading;

  const rejectionModal = useDisclosure();
  const { isOpen: openModal, toggle: toggleModal } = useDisclosure();

  const [amount, setAmount] = useState<number | undefined>();
  const [comment, setComment] = useState('');
  const [tookTookTooLongType, setTookTooLongType] = useState(
    TookTooLongType.jobTookTooLong,
  );
  const [improvement, setImprovement] = useState<string>();
  const [timeSuggestionInSeconds, setTimeSuggestionInSeconds] =
    useState<number>();
  const changeRequestModal = useDisclosure();

  const tookTooLongHeader = useMemo(() => {
    if (tookTookTooLongType === TookTooLongType.jobTookTooLong) {
      return t('runJob.jobTookTooLongHeaderText');
    } else if (tookTookTooLongType === TookTooLongType.jobTookRegTooLong) {
      return t('runJob.jobTookTooRegLongHeaderText');
    }
    return '';
  }, [tookTookTooLongType, t]);

  const actualDuration = useMemo(() => {
    if (runningJob) {
      const runningJobDuration = runningJob.duration as unknown as number;
      const startInMilliseconds = new Date(
        (runningJob?.last_start ?? runningJob?.start) as unknown as string,
      ).valueOf();
      const durationFromLastStartToNowInMilliseconds =
        new Date().valueOf() - startInMilliseconds;

      return (
        runningJobDuration * 1000 + durationFromLastStartToNowInMilliseconds
      );
    } else return 0;
  }, [runningJob]);

  const showToastSchemaIsNotValid = (message: string = 'yup.notValid') => {
    void present({
      message: t(message),
      duration: 1000,
      color: 'danger',
    });
  };

  const onModalSubmit = (cancel = false) => {
    runningAnySchema
      .isValid({
        amount,
        comment,
      })
      .then(function(valid) {
        if (valid && amount) {
          endJob({
            runningJobId: Number(runningJob?.id),
            amount: cancel ? 0 : amount,
            comment,
            improvement_comment: improvement,
            time_suggestion: timeSuggestionInSeconds,
          });
        } else {
          showToastSchemaIsNotValid();
        }
      })
      .catch((error) => {
        showToastSchemaIsNotValid(error as string);
      });
  };

  return (
    <IonPage>
      <Toolbar>{t('runJob.toolbar.title')}</Toolbar>
      <IonContent>
        <IonLoading isOpen={loading} />
        <TookTooLongModal
          tookTooLongHeader={tookTooLongHeader}
          showModal={rejectionModal.isOpen}
          toggleShowJobTookTooLongModal={rejectionModal.toggle}
          onModalSubmit={onModalSubmit}
          improvement={improvement ?? ''}
          setImprovement={(im) => setImprovement(!im ? undefined : im)}
          improvementLabel="runJob.improvementText"
          actualDuration={actualDuration}
          demandedDurationPerPiece={
            runningJob?.job?.duration as unknown as string
          }
          doneAmount={amount || 0}
          tookTooLongType={tookTookTooLongType}
          timeSuggestionInSeconds={timeSuggestionInSeconds ?? 0}
          setTimeSuggestionInSeconds={(time) =>
            setTimeSuggestionInSeconds(!time ? undefined : time)
          }
          disabled={isPending}
        />
        {!loading ? (
          <div className="grid grid-cols-1 gap-2 p-2">
            <Label>{job?.name}</Label>
            <FlexRow justifyContent="between">
              <Text>{t('runJob.summedAmount') + ' ' + summedJob?.total}</Text>
              <Text className="mr-3">
                {t('runJob.remaining') + ' ' + summedJob?.remaining}
              </Text>
            </FlexRow>
            {job?.important_message ? (
              <div>
                <Text color="red-500" className="text-xl mt-4">
                  {`${t('runJob.important')} `}
                </Text>
                <Text color="red-500">{`${job.important_message}`}</Text>
              </div>
            ) : null}
            {!job?.description || (
              <LabeledText label="runJob.jobDescription">
                {job.description}
              </LabeledText>
            )}
            <NumberInput
              name="amount"
              label={t('runJob.amount.label')}
              min="0"
              required
              value={amount ?? undefined}
              onChange={(val) => setAmount(val)}
            />
            <Textarea
              name="comment"
              label={t('runJob.comment.label')}
              autoGrow
              rows={3}
              value={comment}
              onChange={(val) => setComment(val ?? '')}
            />
            <LinkButton
              onClick={changeRequestModal.toggle}
              className="mb-2 z-20 text-xl"
            >
              jobTile.changeRequest.text
            </LinkButton>
            <ChangeRequestModal
              isOpen={changeRequestModal.isOpen}
              toggleIsOpen={changeRequestModal.toggle}
              jobId={jobId}
            />
            <FlexRow justifyContent="between">
              <PictureViewer
                pictureIds={job?.job_pictures ?? []}
                pictureType={PictureType.JOB}
                projectOrRunningAnyId={runningJob?.id}
                classNamePreviewPicture=""
              />
            </FlexRow>
          </div>
        ) : null}
      </IonContent>
      <IonFooter className="bg-white flex w-full">
        <Button
          className="min-w-fit"
          onClick={() => toggleModal()}
          disabled={isPending}
        >
          runJob.cancel
        </Button>
        <Button
          className="w-full"
          onClick={() => onModalSubmit()}
          disabled={isPending}
        >
          runJob.end
        </Button>
        <TakePhotoButton
          disabled={isPending}
          runningAnyId={runningJob?.id ?? ''}
          pictureType={PictureType.JOB}
          className="h-14 w-15"
        />
      </IonFooter>
      <CancelModal
        openModal={openModal}
        toggleOpenModal={toggleModal}
        onSubmit={() => onModalSubmit(true)}
        disabled={isPending}
      />
    </IonPage>
  );
}

